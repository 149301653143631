import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-reveal';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Button, Divider, Form, Icon, Input, Menu, Select, Tab, TextArea } from 'semantic-ui-react';
import axios from 'axios';
import GConf from '../../AssetsM/generalConf';
import useGetFamilleArticle from '../Assets/Hooks/fetchArticlesFamille';
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import FrameForPrint from '../Assets/frameForPrint';
import usePrintFunction from '../Assets/Hooks/printFunction';
import TableGrid from '../Assets/tableGrid';
import { _ } from 'gridjs-react';

const CustomTabs = ({activeIndex, setActiveIndex }) => {
    return(<>
      <div className='row mb-3'>
        <div className='col-12 col-lg-8 align-self-center' >
          <div style={{width:'100%', overflowX: 'auto', overflowY : 'hidden',  whiteSpace:'nowrap'}}>
            <Menu secondary >
                <Menu.Item active={activeIndex == 0} className='rounded-pill' onClick={ () => setActiveIndex(0)}>
                  <span className='text-success'>
                    <b>
                      <span className='bi bi-caret-up-fill'></span> Revenue Caisses
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 1} className='rounded-pill' onClick={ () => setActiveIndex(1)}>
                  <span className='text-success'>
                    <b>
                      <span className='bi bi-caret-up-fill'></span> Revenue
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 2} className='rounded-pill' onClick={ () => setActiveIndex(2)}>
                  <span className='text-success'>
                    <b>
                      <span className='bi bi-caret-up-fill'></span> Electro
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 3} className='rounded-pill' onClick={ () => setActiveIndex(3)}>
                  <span className='text-danger'>
                    <b>
                      <span className='bi bi-caret-down-fill'></span> Depenses
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 4} className='rounded-pill' onClick={ () => setActiveIndex(4)}>
                  <span className='text-danger'>
                    <b>
                      <span className='bi bi-caret-down-fill'></span> MG-Depot 
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 5} className='rounded-pill' onClick={ () => setActiveIndex(5)}>
                  <span className='text-success'>
                    <b>
                      <span className='bi bi-caret-up-fill'></span> BONS 
                    </b>
                  </span>
                </Menu.Item>
                <Menu.Item active={activeIndex == 6} className='rounded-pill' onClick={ () => setActiveIndex(6)}>
                  <span className='text-success'>
                    <b>
                      <span className='bi bi-caret-up-fill'></span> SAREF 
                    </b>
                  </span>
                </Menu.Item>
            </Menu>
          </div>
        </div>
         
      </div>
    </>)
  }


function FamiliaPage() {
     let [caissesR, setCaisseR] = useState([])
     let [electroR, setElectroR] = useState([])
     let [Revenue, seRevenueData] = useState([])
     let [depenseData, setDepenseData] = useState([])
     let [sarefData, setSarefData] = useState([])
     let [bonsData, setBonsData] = useState([])
     let [mgDepotData, setMgDepotData] = useState([])
     let [budgetData, setBudgetData] = useState([])

     const [activeIndex, setActiveIndex] = useState(0)

     const panesRes = [
        {
          menuItem: { key: 'attent',  content: <span className='text-warning'><b><span className='bi bi-hourglass-split'></span> En Attent</b></span> , className:'rounded-pill'},
          render: () => <TableGrid tableData={caissesR} columns={['Date','Caisse1','Caisse2','Voir']} />,
        },
        {
          menuItem: { key: 'accept',  content: <span className='text-success'><b><span className='bi bi-check-square-fill'></span> Accepteé</b></span> , className:'rounded-pill' },
          render: () => <TableGrid tableData={Revenue} columns={['Date','Valeur', 'description','Voir']} />,
        },
        {
          menuItem: { key: 'refuse',  content: <span className='text-danger'><b><span className='bi bi-x-square-fill'></span> Refuseé</b></span>, className:'rounded-pill' },
          render: () => <TableGrid tableData={electroR} columns={['De','Vers', 'Valeur','Voir']} />,
        },
        {
            menuItem: { key: 'one',  content: <span className='text-success'><b><span className='bi bi-check-square-fill'></span> Accepteé</b></span> , className:'rounded-pill' },
            render: () => <TableGrid tableData={depenseData} columns={['Date','Valeur', 'description','Voir']} />,
        },
        {
            menuItem: { key: 'twu',  content: <span className='text-danger'><b><span className='bi bi-x-square-fill'></span> Refuseé</b></span>, className:'rounded-pill' },
            render: () => <TableGrid tableData={mgDepotData} columns={['Date','Valeur', 'description','Voir']} />,
        },
        {
            menuItem: { key: 'three',  content: <span className='text-danger'><b><span className='bi bi-x-square-fill'></span> Refuseé</b></span>, className:'rounded-pill' },
            render: () => <TableGrid tableData={bonsData} columns={['Date','Valeur', 'description','Voir']} />,
        },
        {
            menuItem: { key: 'four',  content: <span className='text-danger'><b><span className='bi bi-x-square-fill'></span> Refuseé</b></span>, className:'rounded-pill' },
            render: () => <TableGrid tableData={sarefData} columns={['Date','Valeur', 'description','Voir']} />,
        },
         
      ]

     useEffect(() => {
        axios.post(`${GConf.ApiLink}/familia/finance`, {
            TAG: 'TAG',
        })
        .then(function (response) {
 
                let articleListContainer = []
                response.data.caissesR.map( (getData) => articleListContainer.push([
                getData.RC_Date,
                parseInt(getData.Caisse_1).toFixed(3),
                parseInt(getData.Caisse_2).toFixed(3),
                _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert((parseInt(getData.Caisse_1)+parseInt(getData.Caisse_2)).toFixed(3))}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
                ],))
                setCaisseR(articleListContainer) 
                
                let electroCont = []
                response.data.electroR.map( (getData) => electroCont.push([
                    getData.De_Date,
                    getData.Vers_Date,
                    parseInt(getData.Carnet_Value).toFixed(3),
                    _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
                ],))
                setElectroR(electroCont)

                let revenueCount = []
                let RevenueList = response.data.budgetData.filter((data) => data.Subgenre === 'REVENUES')
                 
                    RevenueList.map( (getData) => revenueCount.push([
                    getData.DB_Date,
                    parseInt(getData.BD_Value).toFixed(3),
                    getData.DB_Description,
                    _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
                ],))
                seRevenueData(revenueCount)

                let depenseCount = []
                let DepenseList = response.data.budgetData.filter((data) => data.Subgenre === 'DEPENSES')
                 
                    DepenseList.map( (getData) => depenseCount.push([
                    getData.DB_Date,
                    parseInt(getData.BD_Value).toFixed(3),
                    getData.DB_Description,
                    _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
                ],))
                setDepenseData(depenseCount)

                let depotCount = []
                let DepotList = response.data.budgetData.filter((data) => data.Subgenre === 'DEPOT')
                 
                    DepotList.map( (getData) => depotCount.push([
                    getData.DB_Date,
                    parseInt(getData.BD_Value).toFixed(3),
                    getData.DB_Description,
                    _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
                ],))
                setMgDepotData(depotCount)

                let sarefCount = []
                let SarefList = response.data.budgetData.filter((data) => data.Subgenre === 'SAREF')
                 
                    SarefList.map( (getData) => sarefCount.push([
                    getData.DB_Date,
                    parseInt(getData.BD_Value).toFixed(3),
                    getData.DB_Description,
                    _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
                ],))
                setSarefData(sarefCount)

                let bonsCount = []
                let BonsList = response.data.budgetData.filter((data) => data.Subgenre === 'BONS')
                 
                    BonsList.map( (getData) => bonsCount.push([
                    getData.DB_Date,
                    parseInt(getData.BD_Value).toFixed(3),
                    getData.DB_Description,
                    _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => alert(getData.DB_Description)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
                ],))
                setBonsData(bonsCount)
             
            setBudgetData(response.data.budgetData)
            
        }).catch((error) => {
            if(error.request) {
            
            }
          });
     },[])
    return ( <>
            <CustomTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex}    /> 
                   
            <Tab menu={{ secondary: true }} activeIndex={activeIndex} panes={panesRes}  className='no-menu-tabs mt-2' />
        </> );
}

export default FamiliaPage;